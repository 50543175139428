import YouTube from "react-youtube";

function Videos() {
    const opts = {
        height: '200',
        width: '100%',
        playerVars: {
            autoplay: 0,
        },
        origin: 'https://evasis.net'
    };

    return (
        <section className='videos'>
            <h2 className='title-main'>Videolar</h2>
            <div className='row'>
                <div className='col-md-4 col-sm-6'>
                    <div className='video-item'>
                        <YouTube videoId="tVnL_bYyLjw" opts={opts}/>
                        <span className='evasis-font'>Tanıtım Filmi</span>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='video-item'>
                        <YouTube videoId="rFmmjl5bMrU" opts={opts}/>
                        <span className='evasis-font'>Geliştirme Süreci (Prototip)</span>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='video-item'>
                        <YouTube videoId="y6L0Ez-d_tk" opts={opts}/>
                        <span className='evasis-font'>Denge Oyunu Üzerinde Deprem Tespitini Denedik!</span>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='video-item'>
                        <YouTube videoId="0qi-eiiUHq4" opts={opts}/>
                        <span className='evasis-font'>Kurulumu Nasıl Yaparım?</span>
                    </div>
                </div>
                <div className='col-md-4 col-sm-6'>
                    <div className='video-item'>
                        <YouTube videoId="U5H9BQKz2oc" opts={opts}/>
                        <span className='evasis-font'>Ayarlar menüsündeki "Sallantı Sınırı" nasıl belirlenir?</span>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Videos;