import React from "react";
import img1 from "../../img/setup/sensor_1.PNG";
import img2 from "../../img/setup/sensor_2.PNG";
import img3 from "../../img/setup/sensor_3.PNG";
import img4 from "../../img/setup/sensor_4.PNG";

const HueDaylightSensivity = props => {
    return (
        <section>
            <h2 className='title'>Philips Hue hareket sensörüne sahip misiniz?</h2>

            <div className='image-container bg-white p-4 text-left'>

                <p>
                    Cevabınız evet ise; Philips Hue mobil uygulamasını açın ve sensör ayarlarınızı aşağıda görüldüğü gibi güncelleyin.
                </p>

                <p>
                    Bu ayarlar hareket sensörünüzün gün ışığından etkilenmesini engelleyecektir.
                </p>

                <div className='row'>
                    <div className='col'>
                        <img alt='hue sensor settings 1' width='100%' src={img1}/>
                    </div>
                    <div className='col'>
                        <img alt='hue sensor settings 2' width='100%' src={img2}/>
                    </div>
                    <div className='col'>
                        <img alt='hue sensor settings 3' width='100%' src={img3}/>
                    </div>
                    <div className='col'>
                        <img alt='hue sensor settings 4' width='100%' src={img4}/>
                    </div>
                </div>


            </div>

            <br/>
            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
}

export default HueDaylightSensivity;