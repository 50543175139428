import { useState, useEffect } from 'react';

function MainVideo() {
    const [showVolumeNotification, setVolumeNotification] = useState(true);

    useEffect(() => {
        // Update the document title using the browser API
        setTimeout(() => {
            setVolumeNotification(false);
        }, 10000)
    });

    return (<section>
        <div className="iframe">
            <iframe width='500px'
                    height='294px'
                    allow='autoplay'
                    frameBorder='0'
                    title='evasis tanıtım filmi'
                    src='https://player.vimeo.com/video/496571687?autoplay=1&background=1&muted=1&title=0&loop=1&badge=0&byline=0&portrait=0'>
            </iframe>

            <div className='volume-info-web'>
                <div className='volume-notification' style={{display: showVolumeNotification ? 'block' : 'none' }}>
                    <span className='evasis-font'>Sesini açın</span>
                    <div className='volume-arrow-down'/>
                </div>
            </div>

            <div className='volume-info-mobile'>
                <div className='volume-notification' style={{display: showVolumeNotification ? 'block' : 'none' }}>
                    <span className='evasis-font'>Sesini açın</span>
                </div>
            </div>
        </div>
    </section>)
}

export default MainVideo;