import React from "react";
import saveApp from "../../img/setup/saveapp.GIF";


const SaveApp = props => {
    return (
        <section>
            <h2 className='title'>Uygulamayı Telefonunuza Yükleyin</h2>

            <div className='image-container  bg-white p-4 text-left'>
                <table className='gateway-table' >
                    <thead>
                        <tr>
                            <td>iOS Kullanıcıları</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>
                                <ol>
                                    <li>Safari tarayıcısını kullanarak uygulama erişim linkini açın</li>
                                    <li>Paylaş icon'una tıklayın</li>
                                    <li>Ana ekrana ekleyi seçin</li>
                                    <li>Menüden evasis uygulamasına erişebiliriniz.</li>
                                </ol>
                            </td>
                        </tr>
                    </tbody>
                </table>

                <table className='gateway-table' >
                    <thead>
                    <tr>
                        <td>Android Kullanıcıları</td>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>
                            <ol>
                                <li>Chrome tarayıcısını kullanarak uygulama erişim linkini açın</li>
                                <li>Sağ üstte yer alan üç noktaya tıklayın</li>
                                <li>'Ana ekrana ekle' seçeneğini seçin</li>
                                <li>Menüden evasis uygulamasına erişebiliriniz.</li>
                            </ol>
                        </td>
                    </tr>
                    </tbody>
                </table>

                <br/>

                <div className='image-container'>
                    <img alt='save app' width='100%' src={saveApp}/>
                </div>

            </div>

            <br/>

            <button className='button danger mr-button' onClick={()=>{props.goToStep(7)}}>Geri</button>
            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
}

export default SaveApp;