import React from "react";

const Final = props => {
    return (
        <section>
            <h2 className='title'>🎉 Kurulum aşamalarını tamamladınız</h2>

            <br/>

            <button className='button danger mr-button' onClick={props.firstStep}>Başa Dön</button>
            <button className='button danger' onClick={props.previousStep}>Geri</button>
        </section>
    )
};

export default Final;