function TechnicalInfo() {
    return (
        <section className='row no-gutters technicalInfo'>
            <div className='col-sm-6 col-12'>
                <article className='p-3'>
                    <h2 className='title-main'>Teknik Özellikler</h2>
                    <table>
                        <tr>
                            <td>Model No</td>
                            <td>R3BP</td>
                        </tr>
                        <tr>
                            <td>Wireless LAN</td>
                            <td>2.4GHz + 5GHz 802.11.b/g/n/ac</td>
                        </tr>
                        <tr>
                            <td>Ethernet</td>
                            <td>300Mbps Gigabit</td>
                        </tr>
                        <tr>
                            <td>USB Portları</td>
                            <td>4 adet USB 2.0</td>
                        </tr>
                        <tr>
                            <td>Boyutlar</td>
                            <td>10x10x7 (cm)</td>
                        </tr>
                        <tr>
                            <td>Güç adaptörü</td>
                            <td>5V/2.5A DC</td>
                        </tr>
                        <tr>
                            <td>Çalışma Sıcaklığı</td>
                            <td>0°C ila 50°C Aralığı</td>
                        </tr>
                        <tr>
                            <td>Güç girişi</td>
                            <td>USB Type C</td>
                        </tr>
                        <tr>
                            <td>Renk</td>
                            <td>Beyaz</td>
                        </tr>
                    </table>
                </article>
            </div>

            <div className='col-sm-6 col-12'>
                <article className='p-3'>
                    <h2 className='title-main'>Limitler</h2>
                    <p className='limit-detail'>Aylık yüksek sabit ücretler ödemenizi istemiyoruz, aynı zamanda güvenliğinizi en iyi şekilde sağlayabilmek için aşağıdaki kullanım limitlerini belirledik. Limitlerin sizin için yetersiz olduğunu düşünüyorsanız lütfen bizimle iletişime geçin.</p>
                    <table>
                        <tr>
                            <td>Bildirim alabilen cihaz sayısı</td>
                            <td>1 cihaz</td>
                        </tr>
                        <tr>
                            <td>Bildirim gönderimi (Haftalık)</td>
                            <td>40 adet</td>
                        </tr>
                        <tr>
                            <td>Uzaktan erişim</td>
                            <td>Yok</td>
                        </tr>
                        <tr>
                            <td>Aynı ağ üzerinden erişim</td>
                            <td>Sınırsız</td>
                        </tr>
                        <tr>
                            <td>Akıllı prizlere tanımlanan görev sayısı</td>
                            <td>10 adet görev</td>
                        </tr>
                    </table>
                </article>
            </div>
        </section>

    )
}

export default TechnicalInfo;