import React from "react";
import bridge from "../../img/setup/bridge.gif";


const HueConnection = props => {
    return (
        <section>
            <h2 className='title'>‘Philips Hue cihazınızın link düğmesine basın’ sesini duyduğunuzda Philips Hue köprüsü üzerinde bulunan düğmeye basın.</h2>

            <div className='image-container'>
                <img alt='hue connect' width='100%' src={bridge}/>
            </div>


            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
}

export default HueConnection;