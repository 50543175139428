import './App.css';
import React, {Suspense, useEffect} from 'react';
import {BrowserRouter as Router, Route, Switch, withRouter, useLocation} from "react-router-dom";
import HomePage from "./components/HomePage/HomePage";
import Policy from "./components/Policy/Policy";
import Setup from "./components/Setup/Setup";
import Warranty from "./components/Warranty/Warranty";
import WHADetail from "./components/WHADetail/WHADetail";
const renderLoader = () => <p>Loading</p>;

function _ScrollToTop(props) {
    const { pathname } = useLocation();
    useEffect(() => {
        window.scrollTo(0, 0);
    }, [pathname]);
    return props.children
}

const ScrollToTop = withRouter(_ScrollToTop);

function App() {
    return (
        <div className="App">
            <Router>
                <ScrollToTop>
                    <Switch>
                        <Route path="/policy">
                            <Suspense fallback={renderLoader()}>
                                <Policy/>
                            </Suspense>
                        </Route>
                        <Route path="/setup">
                            <Suspense fallback={renderLoader()}>
                                <Setup/>
                            </Suspense>
                        </Route>
                        <Route path="/warranty">
                            <Suspense fallback={renderLoader()}>
                                <Warranty/>
                            </Suspense>
                        </Route>
                        <Route path="/akilli-ev-guvenlik-seti">
                            <Suspense fallback={renderLoader()}>
                                <WHADetail/>
                            </Suspense>
                        </Route>
                        <Route path="/">
                            <Suspense fallback={renderLoader()}>
                                <HomePage />
                            </Suspense>
                        </Route>
                    </Switch>
                </ScrollToTop>
            </Router>
        </div>
    );
}

export default App;
