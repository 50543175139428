import React, {lazy} from "react";

const Footer = lazy(() => import('../shared/Footer'));
const Header = lazy(() => import('../shared/Header'));

function Policy() {
    return (
        <section>
            <Header/>

            <article className='policy'>
                <h1>Gizlilik ve Güvenlik Politikası & KVKK Aydınlatma Metni</h1>
                <p>
                    EVASİS web adresi https://evasis.net (Bundan sonra Evasis Web Sitesi olarak anılacaktır.) adresinde yayımlanan İşbu “Gizlilik ve Güvenlik Politikası & KVKK Aydınlatma Metni” 6698 Sayılı Kişisel Verilerin Korunması Kanunu (Bundan sonra KVKK olarak anılacaktır.) 10. Maddesinde yer verilen veri sorumlusunun aydınlatma yükümlülüğü sebebi ile siz değerli ziyaretçilerimizin temel hak ve özgürlüklerinizi korumak amacıyla, şirketimiz tarafından elde edilen kişisel verilerinizi hangi hukuki gerekçelerle ve hangi yöntemlerle topladığımızı, elde edilen verilerinizi hangi amaçla işlediğimizi, toplanan kişisel verilerinizin kimlere ve hangi amaçla aktarılabileceğimizi, veri sorumlusu olarak şirketimize başvuru haklarınızı belirtmek ve bildirmek amacı ile sizlerin dikkatinize sunmak adına hazırlanmıştır.
                </p>
                <p>
                    KVKK, 07.04.2016 tarihinde 29677 sayılı Resmi Gazetede yayımlanarak yürürlüğe girmiştir. Bu kanun, kişisel verileri işlenen gerçek kişilerin Anayasa tarafından da korunan özel hayatın gizliliği de dahil olmak üzere, temel hak ve özgürlüklerini korumakta ve kişisel verileri işleyen gerçek ve tüzel kişilerin veri sorumlusu sıfatıyla yükümlülüklerini belirlemektedir.
                </p>
                <p>
                    EVASİS, KVKK ve yürürlükteki ilgili diğer yasal mevzuat hükümleri doğrultusunda İşbu Gizlilik ve Güvenlik Politikası & KVKK Aydınlatma Metni Hakkında Kanun Uyarınca Aydınlatma Metninde güncelleme ve değişiklik yapma hakkını saklı tutmaktadır.
                </p>

                <h2>1. KİŞİSEL VERİ</h2>
                <p>
                    KVKK m.3/d bendinde Kişisel veri; “kimliği belirli veya belirlenebilir gerçek kişiye ilişkin her türlü bilgi” olarak ifade edilmektedir. Bu her türlü bilgi, belli bir kimsenin adı, soyadı, doğum tarihi, cinsiyeti,  kimlik numarası, e-mail adresi, ikametgah/teslimat adresi,  telefon numarası, gibi bilgiler olup, kişiyi diğer kişilerden ayırabilme ve belirlenebilir kılabilme özelliklerine haiz olabilmeleri nedeniyle yasal mevzuatlarımızla koruma altına alınmıştır.
                </p>

                <h2>2. KİŞİSEL VERİLERİN İŞLENMESİ VE İŞLEME AMACI</h2>
                <p>
                    Kişisel verilerin işlenmesi; “kişisel verilerin tamamen veya kısmen otomatik olan yollarla elde edilmesi, kaydedilmesi, depolanması, muhafaza edilmesi, değiştirilmesi, yeniden düzenlenmesi, açıklanması, aktarılması, devralınması, elde edilebilir hâle getirilmesi, sınıflandırılması ya da kullanılmasının engellenmesi gibi veriler üzerinde gerçekleştirilen her türlü işlem” olarak ifade edilmektedir.
                </p>
                <p>
                    EVASİS tarafından elde edilen kişisel verileriniz, İşbu Gizlilik & Güvenlik Politikası ile Kişisel Verilerin Korunması Hakkında Kanun Uyarınca Aydınlatma Metninde belirtilen amaçlarla sınırlı olarak ve 6563 Sayılı Elektronik Ticaretin Düzenlenmesi Hakkında Kanun, 6698 Sayılı Kişisel Verilerin Korunması Kanunu, 6502 sayılı Tüketicinin Korunması Hakkında Kanun başta olmak üzere, ilgili diğer yasal mevzuatlardan doğan yasal yükümlülüklerimizin yerine getirilmesi sebebi ile şirketimiz tarafından işlenmektedir.
                </p>
                <b>Başlıca Kişisel Veri İşleme Amaçlarımız;</b>
                <ol>
                    <li>Müşteri memnuniyetine ilişkin aktivitelerin planlanması ve icra edilmesi,</li>
                    <li>Ürün veya hizmetlerin satış süreçlerinin planlanması ve icrası,</li>
                    <li>Satış sonrası destek hizmetleri aktivitelerinin planlanması ve icrası,</li>
                    <li>Müşteri taleplerinin/şikayetlerinin takibi,</li>
                    <li>Kişisel verilerin doğru ve güncel olmasının sağlanması,</li>
                </ol>

                <p>
                    EVASİS web sitesine erişim sağlanması halinde, ekrana gelecek anasayfadaki sağ üst bölümde altıncı sekmede yer alan “İletişim” sekmesine tıklayarak ekrana gelen “Bizimle İletişime Geçin!“ bölümünde şirketimiz müşteri temsilcileri veya ilgili süreçleri yöneten ilgili birimdeki yetkili kişilerle iletişime geçebilmeniz amacı ile ad, soyad, e-mail adresinizi, telefon numaranızı ve mesajınızı hür iradeniz ile yazarak doldurmanız ve “Gönder” butonuna tıklayarak şirketimize iletmeniz halinde ad, soyad, ve e-mail adresi ve telefon numaranızın (belirtilen kişisel verilerinizin) işlenmesi hususunda açık rıza verdiğiniz kabul edilmektedir.
                </p>
                <p>
                    Ayrıca, yine iletişim sekmesinde yer alan Whatsapp logosu ile Arama butonuna tıklanması ve şirketimiz müşteri temsilcisi ile irtibat haline geçilmesi halinde, irtibatın kurulduğu cep telefonu numaranız şirketimiz tarafından işlenmektedir.
                </p>
                <p>
                    EVASİS tarafından işlenen kişisel veriler, ziyaretçilerimiz veya müşterilerimizle iletişim kurabilme, onları reklam ve kampanyalardan haberdar edebilme ve yukarıda belirtilen başlıca kişisel veri işleme amaçlarımız başlığı altında yer verilen amaçlar ile sınırlı olarak, kanun ve ilgili diğer yasal mevzuatlara uygun ve politikada yer verilen veri işleme amaçları ile bağlantılı olacak süre kadar işlenmektedir.
                </p>

                <h2>3. KİŞİSEL VERİLERİN İŞLENMESİNDE TEMEL İLKELER</h2>
                <p>EVASİS, kişisel verileri işlerken aşağıdaki temel ilkelere uyduğunu ve uyacağını kabul, beyan ve taahhüt etmektedir.</p>
                <ol>
                    <li>Hukuka ve dürüstlük kurallarına uygun olma,</li>
                    <li>Doğru ve gerektiğinde güncel olma,</li>
                    <li>Belirli, açık ve meşru amaçlar için işleme,</li>
                    <li>İşlediği amaçla bağlantılı, sınırlı ve ölçülü olma,</li>
                    <li>İşlediği amaç için gerekli olan süre kadar muhafaza etme.</li>
                </ol>

                <h2>4. VERİ GÜVENLİĞİ</h2>
                <p>
                    EVASİS, KVKK, Kişisel Verilerin Silinmesi, Yok Edilmesi ve Anonim Hale Getirilmesi Hk. Yönetmelik ve diğer ilgili yasal mevzuat hükümleri uyarınca, kişisel verilerin silinmesi, yok edilmesi veya anonimleştirilmesine ilişkin gerekli sistemleri ve denetim mekanizmalarını kendi şirket bünyesi içerisinde oluşturacağını, kişisel verilerin hukuka aykırı olarak işlenmesini önleyeceğini, kişisel verilere hukuka aykırı erişimi önleyeceğini, kişisel verilerin muhafazasını sağlayacağını ve bu doğrultuda her türlü teknik ve idari tedbiri alacağını kabul ve taahhüt eder.
                </p>
                <p>
                    EVASİS, kendi şirket bünyesi içerisinde ve ayrıca kişisel verilerin başka bir gerçek kişi veya tüzel kişi tarafından işlenmesi halinde gerekli denetimleri yapacağını veya yaptıracağını bu konuda gerekli her türlü teknik ve idari tedbiri alacağını kabul ve taahhüt eder.
                </p>
                <p>
                    EVASİS, işlenen kişisel verilerin hukuka aykırı olarak başkaları tarafından ele geçirilmesi halinde, bu durumun anlaşılmasına müteakip durumu en kısa süre içerisinde ilgili kişiye ve Kişisel Verileri Koruma Kuruluna bildirecektir, EVASİS, bu konuda ilgili kişinin kayıtlı e-mail adresine durumu bildirecektir.
                </p>

                <h2>5. KİŞİSEL VERİLERİN AKTARIMI</h2>

                <h3>B.YURTİÇİNDE VERİ AKTARILMASI</h3>
                <p>EVASİS, elde ettiği kişisel verileri ancak ilgili kişinin açık rızası ile yurtiçinde bulunan üçüncü gerçek veya tüzel kişilere aktarabilmektedir.</p>
                <p>
                    Açık rızası olmaksızın ilgili kişinin kişisel verilerinin üçüncü gerçek veya tüzel kişiye aktarılması aşağıda belirtilen hallerde mümkün olup, belirtilen durumlarda kanuna uygun bir şekilde ve amaçla bağlantılı olarak yetkili kılınan kişilere aktarılabilir.
                </p>

                <ol>
                    <li>Kanunlarda açıkça öngörülmesi</li>
                    <li>Fiili imkânsızlık nedeniyle rızasını açıklayamayacak durumda bulunan veya rızasına hukuki geçerlilik tanınmayan kişinin kendisinin ya da bir başkasının hayatı veya beden bütünlüğünün korunması için zorunlu olması.</li>
                    <li>Bir sözleşmenin kurulması veya ifasıyla doğrudan doğruya ilgili olması kaydıyla, sözleşmenin taraflarına ait kişisel verilerin işlenmesinin gerekli olması.</li>
                    <li>Veri sorumlusunun hukuki yükümlülüğünü yerine getirebilmesi için zorunlu olması.</li>
                    <li>İlgili kişinin kendisi tarafından alenileştirilmiş olması.</li>
                    <li>Bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması.</li>
                    <li>İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması</li>
                </ol>

                <h3>B.YURTDIŞINA VERİ AKTARILMASI</h3>
                <p>
                    EVASİS, elde ettiği kişisel verileri ancak ilgili kişinin açık rızası ile yurtdışında bulunan üçüncü gerçek veya tüzel kişilere aktarabilmektedir.
                </p>
                <p>
                    Kişisel verilerin yurtdışına aktarılabilmesi için yukarıda A bendinde belirtilen yurtiçindeki aktarım şartları ile kişisel verinin aktarılacağı yabancı ülkede;
                </p>

                <ol>
                    <li>Yeterli korumanın bulunması,</li>
                    <li>Yeterli korumanın bulunmaması durumunda Türkiye’deki ve ilgili yabancı ülkedeki veri sorumlularının yeterli bir korumayı yazılı olarak taahhüt etmeleri ve Kurulun izninin bulunması,</li>
                </ol>

                <p>
                    Şartlarının mevcut olması halinde EVASİS, kişisel verileri ilgili kişinin açık rızasını aramaksızın yurt dışına aktarılabilir.
                </p>

                <p>
                    Yukarıda izah edilen yeterli korumaya sahip olan ülkeler Kişisel Verileri Koruma Kurulu tarafından belirlenecek ve ilan edilecektir. Güvenli ülkelerin Kurul tarafından ilan edilmesi durumunda, Kişisel Verileri Koruma Kurulundan izin alınmasına gerek olmaksızın ve yabancı ülkedeki veri sorumlularının yazılı olarak korumayı taahhüt etmelerine gerek bulunmaksızın elde edilen kişisel veri yurtdışına aktarılabilecektir.
                </p>

                <h2>6. İLGİLİ KİŞİNİN SAHİP OLDUĞU HAKLAR</h2>
                <p>Kişisel verisi işlenen gerçek kişiler, Kişisel Verilerin Korunması Kanunun ve ilgili diğer yasal mevzuat hükümlerinin uygulanması amacıyla veri sorumlusu sıfatıyla EVASİS’e  başvurma hakkına sahiptir.</p>
                <p>İlgili Kişinin Haklarına KVKK 11. Maddesinde yer verilmiş olup, belirtilen haklar aşağıdaki gibidir;</p>

                <ol>
                    <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
                    <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
                    <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
                    <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>KVKK ve ilgili diğer yasak mevzuat hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde ilgili kişinin talebi üzerine kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
                    <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
                    <li>KVKK ve ilgili diğer yasak mevzuat hükümlerine uygun olarak işlenmiş olmasına rağmen, işlenmesini gerektiren sebeplerin ortadan kalkması hâlinde ilgili kişinin talebi üzerine kişisel verilerin silinmesini veya yok edilmesini isteme ve kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini talep etme yarınca yapılan işlemlerin, kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
                    <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
                    <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme.</li>
                </ol>

                <h2>7. VERİ SORUMLUSUNA BAŞVURU</h2>

                <p>İlgili Kişi, EVASİS tarafından işlenen kişisel verileri hakkında haklarının kullanılmasına dair işbu politikanın 6.maddesinde yer alan haklarının kullanımı ile bağlantılı en uygun zaman dilimi içerisinde veri sorumlusu sıfatıyla EVASİS’e başvurma yükümlülüğü altında olduğunu kabul ve beyan eder.</p>

                <p>İlgili Kişi, yapmış olduğu başvuruları, e-mail yoluyla “Kişisel Verilerin Korunması Kanunu Hakkında Bilgi Talebi” konu açıklaması ile EVASİS’e iletme yükümlülüğü altında olduğunu kabul ve beyan eder.</p>

                <p>
                    Başvuru Formu ilgili kişi evasis web sitesine üye olduğu mail adresini kullanarak, <a href='destek@evasis.net'> evasis@outlook.com.tr </a> mail adresine gönderilmelidir.
                </p>

                <p>
                    İşbu Politikanın 6. Maddesinde “İlgili Kişinin Sahip Olduğu Haklar” başlığı altında düzenlenen ilgili kişinin sahip olduğu hakların kullanılabilmesi ve veri sorumlusu sıfatı ile ilgili kişiye kanuna ve diğer ilgili yasal mevzuatlara uygun olarak başvurulması durumunda veri sorumlusu başvuruyu kabul edebilir veya gerekçesini açıklayarak reddedebilir.
                </p>

                <p>
                    EVASİS, veri sorumlusu sıfatı ile başvuruyu cevaplaması halinde, cevabı ilgili kişiye yazılı olarak veya elektronik ortamda bildirebilir.
                </p>

                <p>
                    Veri Sorumlusuna Başvuru Usul ve Esasları Hakkında Tebliğ Uyarınca;
                </p>
                <p>
                    EVASİS, başvuruda yer alan talepleri, talebin niteliğine göre en kısa sürede ve en geç otuz
                    gün içerisinde ücretsiz olarak sonuçlandıracağını kabul ve beyan eder.
                </p>

                <p>
                    İlgili Kişinin yapmış olduğu başvurunun reddedilmesi, verilen cevabın yetersiz bulunması veya süresinde (otuz gün) başvuruya cevap verilmemesi hâllerinde; ilgili kişi, veri sorumlusunun cevabını öğrendiği tarihten itibaren otuz (30) ve her hâlde başvuru tarihinden itibaren altmış (60) gün içinde Kişisel Verileri Koruma Kuruluna şikayette bulunma hakkına sahiptir.
                </p>


            </article>

            <div className='divider'/>
            <Footer/>
        </section>

    )
}

export default Policy;