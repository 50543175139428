import React from "react";
import img1 from "../../img/setup/sensor_1.PNG";
import img2 from "../../img/setup/sensor_2b.PNG";

const HueAccessories = props => {
    return (
        <section>
            <h2 className='title'>Philips Hue aksesuarlarını kendi uygulamasında tanımlayın.</h2>

            <div className='image-container bg-white p-4 text-left'>

                <p>
                    Eğer hali hazırda Hue kullanıcısıysanız bu aşamayı büyük ihtimalle tamamlamışsınızdır.
                </p>

                <p>
                    Philips Hue Mobil uygulamasına girin, Ayarlar > Aksesuar Kurulumu > Aksesuar Ekle butonuna tıklayın.
                </p>

                <p>
                    Açılan menüden mevcut aksesuarlarınızı tanımlayın (Hareket sensörü, aydınlatma ürünleri, Hue Tap cihazı vb.)
                </p>

                <div className='row'>
                    <div className='col'>
                        <img alt='hue sensor settings 1' width='100%' src={img1}/>
                    </div>
                    <div className='col'>
                        <img alt='hue sensor settings 2' width='100%' src={img2}/>
                    </div>
                </div>


            </div>

            <br/>
            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
}

export default HueAccessories;