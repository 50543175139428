import instagramImg from '../../img/socials/instagram.png';
import facebookImg from '../../img/socials/facebook.png';
import twitterImg from '../../img/socials/twitter.png';
import youtubeImg from '../../img/socials/youtube.png';
import {LazyLoadImage} from 'react-lazy-load-image-component';

function Socials() {
    return (
        <section className='socials'>
            <h2 className='title-main'>Bizi takip edin</h2>

            <div className='socials-buttons'>
                <a href='https://www.youtube.com/channel/UCKULWVHOrFIrE_2JqGFiOaw' rel="noreferrer" target='_blank'>
                    <LazyLoadImage
                        effect='blur'
                        alt='social youtube'
                        src={youtubeImg} />
                </a>
                <a href='https://www.instagram.com/evasisAlarm' rel="noreferrer" target='_blank'>
                    <LazyLoadImage
                        effect='blur'
                        alt='social instagram'
                        src={instagramImg} />
                </a>
                <a href='http://twitter.com/evasisAlarm' rel="noreferrer" target='_blank'>
                    <LazyLoadImage
                        effect='blur'
                        alt='social twitter'
                        src={twitterImg} />
                </a>
                <a href='https://facebook.com/evasisAlarm' rel="noreferrer" target='_blank'>
                    <LazyLoadImage
                        effect='blur'
                        alt='social facebook'
                        src={facebookImg} />
                </a>
            </div>

        </section>
    )
}

export default Socials;