import evasisProduct from "../../img/product/4.jpg";
import evasisProductSet from "../../img/market/set.jpg";
import hueMotionSensor from "../../img/market/hue-motion-sensor.jpg";
import hueStarter2 from "../../img/market/philips-hue-2-starter.jpg";
import hueTap from "../../img/market/philips-hue-tap.jpg";
import dimmerSwitch from "../../img/market/dimmer-switch.jpg";
import tapoP100 from "../../img/market/tapo-p100.jpg";
import {LazyLoadImage} from 'react-lazy-load-image-component';
import {Link} from "react-router-dom";

function Market() {
    return (
        <section className='shop'>
            <h2 className='title-main'>Market</h2>

            <div className='row'>
                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://web.paym.es/customer_info.php?id=45561'>
                            <LazyLoadImage
                                effect='blur'
                                alt='evasis ürün resmi'
                                src={evasisProduct}/>
                            <span className='evasis-font'>evasis</span>
                            <div className='desc'>Kontrol Ünitesi</div>
                        </a>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <Link to={'/akilli-ev-guvenlik-seti'} rel="noreferrer">
                            <LazyLoadImage
                                effect='blur'
                                alt='evasis akıllı ev güvenlik seti'
                                src={evasisProductSet}/>
                            <span className='evasis-font'>Akıllı Ev Güvenlik Seti </span>
                        </Link>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://www.akakce.com/akilli-sensor/en-ucuz-philips-hue-hareket-sensoru-fiyati,258126546.html'>
                            <LazyLoadImage
                                effect='blur'
                                alt='philips hue hareket sensoru'
                                src={hueMotionSensor}/>
                            <span>Philips Hue Hareket Sensörü</span>
                        </a>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://www.akakce.com/akilli-led-ampul/en-ucuz-philips-hue-renkli-2-li-kumandali-bluetooth-ozellikli-e27-akilli-baslangic-seti-fiyati,720940327.html'>
                            <LazyLoadImage
                                effect='blur'
                                alt='philips hue starter kit'
                                src={hueStarter2}/>
                            <span>Philips Hue Başlangıç Kiti</span>
                        </a>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://www.akakce.com/diger/en-ucuz-philips-hue-dim-switch-fiyati,1052429595.html'>
                            <LazyLoadImage
                                effect='blur'
                                alt='philips hue dimmer switch'
                                src={dimmerSwitch}/>
                            <span>Dimmer Switch (Kumanda)</span>
                        </a>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://www.akakce.com/akilli-anahtar/en-ucuz-philips-hue-tap-fiyati,259494931.html'>
                            <LazyLoadImage
                                effect='blur'
                                alt='philips hue tap'
                                src={hueTap}/>
                            <span>Philips Hue Tap (Kumanda)</span>
                        </a>
                    </div>
                </div>

                <div className='col-md-4 col-sm-6'>
                    <div className='market-item'>
                        <a rel="noreferrer" target='_blank' href='https://www.akakce.com/akilli-priz/en-ucuz-tp-link-tapo-p100-akilli-mini-wi-fi-soketi-fiyati,636897013.html'>
                            <LazyLoadImage
                                effect='blur'
                                alt='tp-link tapoP100'
                                src={tapoP100}/>
                            <span>TP-Link Tapo P100</span>
                        </a>
                    </div>
                </div>
            </div>

        </section>
    )
}

export default Market;