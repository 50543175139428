import {lazy} from "react";
import ekosistem from "../../img/ekosistem.jpg";
import evPlan from "../../img/ev-plan.jpg";
import home from "../../img/home.jpg";
import MainVideo from "./MainVideo";
import Main from "./Main";
import Market from "./Market";
import Videos from "./Videos";
import Socials from "./Socials";
import {LazyLoadImage} from "react-lazy-load-image-component";
import 'react-lazy-load-image-component/src/effects/blur.css';
import TechnicalInfo from "./TechnicalInfo";

const Footer = lazy(() => import('../shared/Footer'));
const Header = lazy(() => import('../shared/Header'));

function HomePage() {
    return (
        <section>
            <Header/>
            <div className='arrow-down'/>
            <div className='wrapper'>

                    <MainVideo/>
                    <div className='divider'/>
                    <Main/>
                    <div className='divider'/>
                    <section className='evplan-container'>

                        <h2 className='title-item earthquake'>Örnek Kullanım Planı</h2>

                        <LazyLoadImage
                            className='evplan'
                            effect='blur'
                            alt='örnek ev planı'
                            src={evPlan}/>
                    </section>
                    <div className='divider'/>
                    <section className='ekosistem-container'>

                        <h2 className='title-item earthquake'>evasis ile beraber kullanılan ürünler:</h2>

                        <LazyLoadImage
                            className='ekosistem'
                            effect='blur'
                            alt='evasis ekosistemi'
                            src={ekosistem}/>
                    </section>
                    <div className='divider'/>
                    <TechnicalInfo/>
                    <div className='divider'/>
                    <Market/>
                    <div className='divider'/>
                    <Videos/>
                    <div className='divider'/>
                    <Socials/>

                <div className='divider'/>

                <section className='home-img'>
                    <h3>Evinzin güvenlik asistanı</h3>
                    <LazyLoadImage
                        effect='blur'
                        alt='smart home'
                        src={home} // use normal <img> attributes as props
                        width='100%' />
                </section>

            </div>
                <Footer/>
        </section>

    )
}

export default HomePage;