import React from "react";

const DefaultGateway = props => {

    return (
        <section>
            <h2 className='title'>Uygulama Erişim Linki</h2>

            <div className='image-container bg-white p-4'>
                <p>
                    Aşağıdaki bağlantıya tıklayarak uygulamaya erişmeyi deneyin <br/> ve sonrasında <b>bu ekrana geri dönün</b>.
                </p>

                <p className='link-bg'>
                    <b>
                        <a target='_blank' rel="noreferrer" href='http://192.168.1.222:3000'>http://192.168.1.222:3000</a>
                    </b>
                </p>
            </div>

            <br/>

            <button className='button danger mr-button' onClick={() => props.goToStep(3)}>Geri</button>
            <button className='button warning mr-button' onClick={props.nextStep}>Uygulama açılmadı</button>
            <button className='button success' onClick={() => props.goToStep(9)}>Uygulama açıldı</button>
        </section>

    )
}

export default DefaultGateway;