import React from "react";
import bridge from "../../img/setup/bridge.jpg";


const HueExist = props => {
    return (
        <section>
            <h2 className='title'>Philips Hue köprüsüne sahip misiniz?</h2>

            <div className='image-container'>
                <img alt='hue connect' width='100%' src={bridge}/>
            </div>

            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button success mr-button' onClick={() => props.goToStep(7)}>Hayır</button>
            <button className='button success' onClick={props.nextStep}>Evet</button>
        </section>

    )
}

export default HueExist;