import miniBridge from "../../img/mini_hue_bridge.png";
import miniSensor from "../../img/mini_hue_sensor.png";
import miniLight from "../../img/mini_hue_light.png";
import disMekanImg from "../../img/circle_dis_mekan.png";
import disMekanSensorImg from "../../img/dis-mekan-sensor.jpg";
import icMekanImg from "../../img/circle_ic_mekan.png";
import icMekanSensorImg from "../../img/ic-mekan-sensor.jpg";
import depremImg from "../../img/circle_earthquake.png";
import depremSallantiTespiti from "../../img/deprem_sallanti.gif";
import tapoImg from "../../img/circle_tapo.png";
import utuPriz from "../../img/utu-priz.jpg";
import miniPlug from "../../img/mini_plug.png";
import miniPhone from "../../img/mini_phone.png";
import miniTap from "../../img/mini_tap.png";
import kontrolImg from "../../img/circle_control.png";
import appHomePage from "../../img/app-homepage.jpg";
import butceDostu from "../../img/butce_dostu.png";
import ReactTooltip from 'react-tooltip';
import {LazyLoadImage} from 'react-lazy-load-image-component';
import ProductsGallery from "./ProductsGallery";

function Main() {

    return (
        <section className='main'>
            <article className='row'>
                <div className='col-md-5 col-sm-12'>
                    <ProductsGallery/>
                </div>

                <div className='col-md-7 col-sm-12 p-3'>
                    <h2 className='title-main'>Akıllı ev dünyasına ilk adımınızı evasis'le atın!</h2>
                    <ul className='list list-dark'>
                        <li> Philips Hue ekipmanlarını güvenlik sistemine dönüştürür.</li>
                        <li> Deprem veya hırsız gibi acil durumlar için senaryolar barındırır, risk durumunda alarmı devreye alır.</li>
                        <li> Dış mekan koruması sayesinde hırsız evinize girmeden haberdar olursunuz.</li>
                        <li> Deprem anında alarm sizi uyandırır, hızlıca güvenli bölgelere ulaşabilmeniz için ışıkları açar.</li>
                        <li> Evden çıkarken açık unuttuğunuz elektronik cihazlarınızı kapatır.</li>
                        <li> Sizi bilgilendirir, evde olmadığınızda aklınız evinizde kalmaz.</li>
                        <li> İç ve dış mekan sıcaklığını size göstererek, ideal oda sıcaklığına ulaşmanızı sağlar.</li>
                        <li> 4 Adet USB istasyonu bulunur.</li>
                    </ul>
                </div>
            </article>

            <br/>
            <hr/>

            <article className='row align-items-center reverse-on-mobile'>
                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item hue'>Dış Mekan Riskli Bölge Güvenliği</h2>
                    <p>
                        Evinizin riskli bölgesindeki hareketi algıladığında <b>hırsız alarmı</b> devreye girer.
                        Riskli bölgeye en yakın olan odanızın ışıkları, belirli bir
                        süre için açılır. Uyku halinde olmanıza karşın, kısa
                        süreli bir siren sesi başlatır. Evde yoksanız dert etmeyin, cep
                        telefonunuza bildirim gönderilir.
                    </p>
                    <ul className='list'>
                        <li> Philips Hue hareket sensörü ile hareket tespiti </li>
                        <li> Işık simülasyonu</li>
                        <li> Kısa süreli siren sesi </li>
                        <li> Cep telefonu bildirimi </li>
                    </ul>
                    <div  className='mini-img-group'>
                        <span>Malzemeler: </span>
                        <img data-tip data-for='hue-bridge' className='mini-img' src={miniBridge} alt='hue bridge'/>
                        <ReactTooltip id='hue-bridge' type="info">
                            <span>Philips Hue Körüsü</span>
                        </ReactTooltip>
                        <img data-tip data-for='hue-sensor' className='mini-img' src={miniSensor} alt='hue motion sensor'/>
                        <ReactTooltip id='hue-sensor' type="info">
                            <span>Philips Hue Hareket Sensörü</span>
                        </ReactTooltip>
                        <img data-tip data-for='hue-light' className='mini-img' src={miniLight} alt='hue lights'/>
                        <ReactTooltip id='hue-light' type="info">
                            <span>Philips Hue Aydınlatma Ürünleri</span>
                        </ReactTooltip>
                    </div>
                </div>

                <div className='col-md-5 col-sm-12'>
                    <div className='img-with-icon'>
                        <LazyLoadImage
                            className='circle-img'
                            effect='blur'
                            alt='dış mekan alarm'
                            src={disMekanImg}/>

                        <LazyLoadImage
                            width={'100%'}
                            effect='blur'
                            alt='dış mekan sensör'
                            src={disMekanSensorImg}/>
                    </div>

                </div>
            </article>

            <hr/>

            <article className='row align-items-center'>
                <div className='col-md-5 col-sm-12'>
                    <div className='img-with-icon'>
                        <LazyLoadImage
                            className='circle-img'
                            effect='blur'
                            alt='iç mekan alarm'
                            src={icMekanImg}/>

                        <LazyLoadImage
                            width={'100%'}
                            effect='blur'
                            alt='iç mekan sensör'
                            src={icMekanSensorImg}/>
                    </div>

                </div>

                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item hue'>İç Mekan Güvenliği</h2>
                    <p>
                        Ev içerisindeki hareketi tespit eder. Hareket algılanması
                        durumunda evin tüm ışıkları yanıp sönmeye başlar.
                        Alarm sesi ve ışıklar birlikte caydırıcı bir etki yaratmak üzere programlanmıştır.
                        Evde yoksanız dert etmeyin, cep telefonunuza bildirim
                        gönderilir.
                    </p>
                    <ul className='list'>
                        <li> Philips Hue hareket sensörü ile hareket tespiti </li>
                        <li> Işık simülasyonu ile caydırıcılık </li>
                        <li> Uzun süreli siren sesi </li>
                        <li> Cep telefonu bildirimi </li>
                    </ul>
                    <div  className='mini-img-group'>
                        <span>Malzemeler: </span>
                        <img data-tip data-for='hue-bridge' className='mini-img' src={miniBridge} alt='hue bridge'/>
                        <ReactTooltip id='hue-bridge' type="info">
                            <span>Philips Hue Körüsü</span>
                        </ReactTooltip>
                        <img data-tip data-for='hue-sensor' className='mini-img' src={miniSensor} alt='hue motion sensor'/>
                        <ReactTooltip id='hue-sensor' type="info">
                            <span>Philips Hue Hareket Sensörü</span>
                        </ReactTooltip>
                        <img data-tip data-for='hue-light' className='mini-img' src={miniLight} alt='hue lights'/>
                        <ReactTooltip id='hue-light' type="info">
                            <span>Philips Hue Aydınlatma Ürünleri</span>
                        </ReactTooltip>
                    </div>
                </div>
            </article>

            <hr/>

            <article className='row align-items-center reverse-on-mobile'>
                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item earthquake'>Deprem Güvenliği</h2>
                    <p>
                        Cihaz sallantı algıladığı zaman <b> deprem alarmı </b> devreye girer. Evin tüm ışıkları açılır ve
                        beyaz renk olur. Bu sayede üzerinize düşebilecek nesneleri rahatlıkla
                        görebilirsiniz. Güvenli bölgeye doğru ilerlemeniz
                        kolaylaşır. Aktif olan alarm sistemini durdurur, bu sayede
                        güvenli bölgelere ilerlerken rahatsız edici bir alarm sesi duymak
                        zorunda kalmazsınız. Uyku durumunda olmanıza karşın kısa süreli
                        bir siren sesi ile sizi uyandırır.
                    </p>
                    <ul className='list'>
                        <li> Sallantı anında erkenden haberdar olma </li>
                        <li> Tüm ışıkların açılması</li>
                        <li> Kısa süreli siren sesi</li>
                        <li> Güvenlik sistemi devre dışı </li>
                        <li> Cep telefonu bildirimi </li>
                    </ul>
                    <div  className='mini-img-group'>
                        <span>evasis yeterlidir. Ek malzeme gerekmez.</span>

                    </div>
                </div>

                <div className='col-md-5 col-sm-12'>

                    <div className='img-with-icon'>
                        <LazyLoadImage
                            className='circle-img'
                            effect='blur'
                            alt='deprem alarm'
                            src={depremImg}/>

                        <LazyLoadImage
                            width={'100%'}
                            effect='blur'
                            alt='sallantı tespiti'
                            src={depremSallantiTespiti}/>
                    </div>


                </div>
            </article>

            <hr/>

            <article className='row align-items-center'>
                <div className='col-md-5 col-sm-12'>
                    <div className='img-with-icon'>
                        <LazyLoadImage
                            className='circle-img'
                            effect='blur'
                            alt='akıllı priz tapo p100'
                            src={tapoImg}/>

                        <LazyLoadImage
                            width={'100%'}
                            effect='blur'
                            alt='ütü akıllı priz kontrol'
                            src={utuPriz}/>
                    </div>

                </div>

                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item plug'>Akıllı Priz Kontrolü</h2>
                    <p>
                        Evden çıkarken veya uyumadan önce ocak, çay veya kahve
                        makinası, ütü ve benzeri elektrikli ev ürünlerinizi açık
                        unutmaktan korkuyorsanız, bu özellik içinizi rahatlatacak.
                        Evinizdeki Tapo P100 akıllı prizleri güvenlik sistemine
                        bağlayın ve ne zaman kapanıp ne zaman açılacağını
                        belirleyin.
                    </p>
                    <p>
                        Ev kameranızı da bu şekilde akıllı prize bağlayarak, güvenlik
                        sisteminiz aktifken çalışmasını, güvenlik sistemi kapalıyken
                        devre dışı olmasını sağlayabilirsiniz.
                    </p>
                    <ul className='list'>
                        <li> Aklınız evinizde kalmaz </li>
                        <li> Elektronik ev aletlerinizi otomatik kapatır </li>
                        <li> İç mekan kamera güvenliğinde kullanılabilir </li>
                        <li> TP-Link Tapo P100 Akıllı Priz kontrolü </li>
                    </ul>
                    <div  className='mini-img-group'>
                        <span>Malzemeler: </span>
                        <img data-tip data-for='tapo-plug' className='mini-img' src={miniPlug} alt='akıllı priz'/>
                        <ReactTooltip id='tapo-plug' type="info">
                            <span>TP-link Tapo P100 Akıllı Priz</span>
                        </ReactTooltip>
                    </div>
                </div>
            </article>

            <hr/>

            <article className='row align-items-center reverse-on-mobile'>
                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item control'>Kontrol Merkezi & Yazılım</h2>
                    <p>
                        Sistemi cep telefonunuzdan, Philips Hue Tap veya Dimmer Switch kumandaları
                        ile kontrol edebilirsiniz.
                        <br/>
                        iOS işletim sistemine sahip mobil cihazlarınızda <b>titreşim veya
                        beni rahatsız etme</b> modunda olma durumunu es geçerek sesli bildirimi size ulaştırır.
                    </p>
                    <ul className='list'>
                        <li> Ev ağınıza bağlı olan tüm akıllı cihazlarınız ile
                            ürün yazılımına ulaşın. (Cep telefonu,
                            bilgisayar, tablet vb.) </li>
                        <li> Yazılım güncellemeleri</li>
                    </ul>
                    <div  className='mini-img-group'>
                        <span>Malzemeler:</span>
                        <img data-tip data-for='phone' className='mini-img' src={miniPhone} alt='mobile phone'/>
                        <ReactTooltip id='phone' type="info">
                            <span>Cep Telefonu</span>
                        </ReactTooltip>
                        {'\u00A0'}{'\u00A0'} veya
                        <img data-tip data-for='hue-tap' className='mini-img' src={miniTap} alt='philips hue tap'/>
                        <ReactTooltip id='hue-tap' type="info">
                            <span>Philips Hue Tap veya Dimmer Switch</span>
                        </ReactTooltip>

                    </div>
                </div>

                <div className='col-md-5 col-sm-12'>

                    <div className='img-with-icon'>
                        <LazyLoadImage
                            className='circle-img'
                            effect='blur'
                            alt='kontrol kumandası'
                            src={kontrolImg}/>

                        <LazyLoadImage
                            style={{maxWidth: 350}}
                            width={'100%'}
                            effect='blur'
                            alt='kontrol kumandası'
                            src={appHomePage}/>
                    </div>

                </div>
            </article>

            <hr/>

            <article className='row align-items-center'>
                <div className='col-md-5 col-sm-12'>
                    <LazyLoadImage
                        className='circle-img'
                        effect='blur'
                        width='150px'
                        alt='bütçe dostu'
                        src={butceDostu}/>
                </div>

                <div className='col-md-7 col-sm-12'>
                    <h2 className='title-item'>Bütçe Dostu</h2>
                    <p>
                        Aylık <b>sabit ücret</b> ödemezsiniz, paranız
                        cebinizde kalır.
                    </p>
                </div>
            </article>

        </section>
    )
}

export default Main;