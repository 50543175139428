import React, {lazy, useEffect} from "react";
import styled from "styled-components";

const Footer = lazy(() => import('../shared/Footer'));
const Header = lazy(() => import('../shared/Header'));

const MainTitle = styled.h1({
    color: "#018ec3",
    marginTop: 20
})

const SubTitle = styled.h2({
    padding: 15, color: 'rgb(255,255,255)', display: 'block', backgroundColor: '#6daad4', textAlign: 'center'
})

const Row = styled.div({
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    '@media(max-width: 788px)': {
        flexDirection: 'column',
    },
    '& div:first-child': {
        flex: 1,
        textAlign: 'center',
        '@media(max-width: 788px)': {
            width: '100%',
        }
    },
    '& div:last-child': {
        flex: 2,
        '@media(max-width: 788px)': {
            width: '100%',
        },
    }
});

const BuyNow = styled.a({
    position: 'absolute',
    top: 40,
    right: 0,

    width: "180px",
    height: "55px",
    fontFamily: "'Roboto', sans-serif",
    fontSize: "15px",
    textTransform: "uppercase",
    letterSpacing: "2.5px",
    fontWeight: "600",
    color: "white",
    backgroundColor: "#E02401",
    border: "none",
    borderRadius: "45px",
    boxShadow: "0px 8px 15px rgba(0, 0, 0, 0.1)",
    transition: "all 0.3s ease 0s",
    cursor: "pointer",
    outline: "none",
    display: 'inline-block',
    textDecoration: 'none',
    lineHeight: 3.6,

    '&:hover': {
        backgroundColor: "red",
        boxShadow: "0px 15px 20px rgba(46, 229, 157, 0.4)",
        color: "#fff",
        transform: "translateY(-5px)"
    }
})

const Image = styled.img({
    width: '100%',
    maxWidth: 400,
    margin: '5px 0 5px 0'
});

const WHADetail = () => {
    useEffect(() => {
        document.title = "Akıllı Ev Güvenlik Sistemi";
    }, []);

    return (
        <section style={{backgroundColor: '#ffffff', textAlign: 'left'}}>
            <Header/>
            <div style={{padding: 15}}>
                <MainTitle>Akıllı ev dünyasına ilk adımınızı evasis güvenlik seti ile atın!</MainTitle>
                <br/>
                <Row>
                    <div style={{textAlign: 'left', flex: 2}}>
                        <h2>Temel Özellikler</h2>
                        <ul>
                            <li>Deprem veya riskli hareket algılanması gibi acil durumlar için senaryolar barındırır,
                                risk durumunda alarm devreye girer.
                            </li>
                            <li>Dış mekan koruması sayesinde evinizde riskli hareket oluşmadan önce haberdar
                                olursunuz.
                            </li>
                            <li>Deprem anında alarm sizi uyandırır, hızlıca güvenli bölgelere ulaşabilmeniz için
                                ışıkları açar.
                            </li>
                            <li>Evden çıkarken açık unuttuğunuz elektronik cihazlarınızı kapatır.</li>
                            <li>Sizi bilgilendirir, evde olmadığınızda aklınız evinizde kalmaz.</li>
                            <li>İç ve dış mekan sıcaklığını size göstererek, ideal oda sıcaklığına ulaşmanızı sağlar.
                            </li>
                            <li>4 Adet USB istasyonu bulunur.</li>
                            <li>Aylık sabit ücret ödemezsiniz, paranız cebinizde kalır.</li>
                        </ul>

                        <br/>
                        <h2>Paket İçeriği</h2>
                        <ul>
                            <li>1 Adet evasis kontrol ünitesi</li>
                            <li>3 Adet White Ambiance E27 Ampul</li>
                            <li>2 Adet Philips Hue Hareket Sensörü</li>
                            <li>1 Adet Dimmer Kumanda</li>
                            <li>1 Adet Tapo P100 Akıllı Priz</li>
                            <li>1 Adet Hue Bridge</li>
                        </ul>
                    </div>

                    <div style={{textAlign: 'center'}}>
                        <div style={{position: 'relative'}}>
                            <Image alt="evasis white ambiance set" style={{maxWidth: 550}} src="https://www.evasis.net/api/images/WA-SET/set.jpg"/>
                            <BuyNow href='https://web.paym.es/customer_info.php?id=234234507'>Satın Al</BuyNow>
                        </div>
                        <hr/>
                        <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'center', width: '100%'}}>
                            <img alt="iç mekan alarm" style={{marginLeft: 10, maxWidth: 100}} width="15%"
                                 src="https://www.evasis.net/api/images/WA-SET/circle_ic_mekan.png"/>
                            <img alt="dış mekan alarm" style={{marginLeft: 10, maxWidth: 100}} width="15%"
                                 src="https://www.evasis.net/api/images/WA-SET/circle_dis_mekan.png"/>
                            <img alt="deprem, sallantı alarmı" style={{marginLeft: 10, maxWidth: 100}} width="15%"
                                 src="https://www.evasis.net/api/images/WA-SET/circle_earthquake.png"/>
                            <img alt="p100 akıllı priz kotnrol" style={{marginLeft: 10, maxWidth: 100}} width="15%"
                                 src="https://www.evasis.net/api/images/WA-SET/circle_tapo.png"/>
                            <img alt="mobil kontrol" style={{marginLeft: 10, maxWidth: 100}} width="15%"
                                 src="https://www.evasis.net/api/images/WA-SET/circle_control.png"/>
                        </div>
                    </div>
                </Row>
            </div>


            <SubTitle>Kontrol Ünitesi (evasis)</SubTitle>
            <Row>
                <div>
                    <Image alt="evasis main image" src="https://www.evasis.net/api/images/WA-SET/evasis.jpg"/>
                    <Image alt="evasis mobile app" src="https://www.evasis.net/api/images/WA-SET/evasis-mobile.jpg"/>
                </div>

                <div style={{padding: 20}}>
                    <p>
                        Güvenlik setinin en önemli bileşenidir. Çalışmanın beyin kısmını oluşturur. Hue Bridge ve diğer
                        akıllı ev bileşenleri ile iletişim kurarak onları güvenlik sistemine çevirmenizi sağlar. Kontrol
                        merkezi görevi görür. Acil durumlarda önceden belirlenmiş senaryoları devreye alarak can ve mal
                        güvenliğinizi korumanıza yardımcı olur.
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>Wifi ile kullanım</li>
                        <li>Manyetik alt aparatı sayesinde tüm sallantıları tespit eder.</li>
                        <li>Tüm Hue aydınlatma ürünleri sisteme dahil edilebilir.</li>
                        <li>Dekoratif, şık bir görnüme sahiptir.</li>
                        <li>Portatiftir, dilediğiniz yerde konumlandırın</li>
                        <li>4 Adet USB istasyonu (USB 2.0)</li>
                        <li>Güç adaptörü dahil</li>
                        <li>Ethernet ağ kablosu dahil</li>
                    </ul>

                    <h3>Teknik Özellikler</h3>
                    <ul>
                        <li>Wireless: 2.4GHz + 5GHz 802.11.b/g/n/ac</li>
                        <li>Ethernet: 300Mbps Gigabit</li>
                        <li>Güç adaptörü: 5V/2.5A DC</li>
                        <li>Çalışma Sıcaklığı: 0°C ila 50°C Aralığı</li>
                        <li>Güç Girişi: USB Type C</li>
                        <li>Renk: Beyaz</li>
                    </ul>

                    <h3>Limitler</h3>
                    <ul>
                        <li>Bildirim alabilmek için bir cihaz bağlanabilir (iOS veya Android)</li>
                        <li>Haftalık 40 Bildirim Gönderimi</li>
                        <li>Uzaktan erişim yok</li>
                        <li>Aynı ağ üzerinden sınırsız erişim</li>
                        <li>Akıllı prizlere 10 adet görev tanımlanabilir</li>
                    </ul>
                </div>

            </Row>

            <SubTitle>Hareket Sensörü</SubTitle>
            <Row>
                <div>
                    <Image alt="hareket sensörü koridorda" src="https://www.evasis.net/api/images/WA-SET/sensor.jpg"/>
                    <Image alt="hareket sensörü balkon" src="https://www.evasis.net/api/images/WA-SET/sensor2.jpg"/>
                    <Image alt="hareket sensörü yüzeyde" src="https://www.evasis.net/api/images/WA-SET/sensor3.jpg"/>
                </div>
                <div style={{padding: 20}}>

                    <p>
                        Güvenlik sisteminiz aktif konumdayken bir hareket algılarsa alarm devreye girer, cep
                        telefonunuza bildirim gönderilir. Evin ışıkları yanıp sönmeye başlar. İç veya dış mekana
                        konumlandırdığınız hareket sensörleri farklı görevleri vardır. Dış mekanda alarm daha kısa
                        süreli çalar ve yakındaki odanın ışıklarını yakar. Cep telefonunuza bildirim gönderir.
                        <br/>İç mekanda hareket algılanır ise, evin ışıkları yanıp sönmeye başlar, yüksek sesli siren
                        sesi devreye girer ve cep telefonunuza bildirim gönderilir.
                        <br/>Aynı zamanda hareket anında aydınlatıma ürünlerinizin açılıp kapanmasını da
                        sağlayabilirsiniz. (Örn: Koridorda konumlandırdığınız hareket sensörü koridorun ışığını
                        açabilir, belirlediğiniz bir süre sonra otomatik kapanır.)
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>Tamamen kablosuz</li>
                        <li>Pille çalışır</li>
                        <li>Evinizin içinde herhangi bir yerde durabilir</li>
                        <li>Tek bir vidayla duvara veya tavana takın ya da birlikte verilen mıknatıs ile herhangi bir
                            mıknatıslı yüzeye yerleştirin.
                        </li>
                        <li>Hareket halinde ışıklarınızı açabilir</li>
                        <li>Oda sıcaklığı tespiti</li>
                        <li>Piller dahildir</li>
                    </ul>

                    <h3>Teknik Özellikler</h3>
                    <ul>
                        <li>Yükseklik: 5.5cm, Uzunluk: 3cm, Genişlik: 5.5cm, Net ağırlık: 0.065kg</li>
                        <li>Desteklenen ses asistanları: Amazon Alexa, Google Assistant, Apple HomeKit, Microsoft
                            Cortana
                        </li>
                        <li>Çalışma Nemi: İç mekan kullanımına uygundur.</li>
                        <li>Çalışma Sıcaklığı: 0°C - 40°C</li>
                        <li>Renk: Beyaz</li>
                        <li>Malzeme : Plastik</li>
                    </ul>
                </div>

            </Row>

            <SubTitle>Hue White Ambiance E27 Ampul</SubTitle>

            <Row>
                <div>
                    <Image alt="3lü white ambiance set" src="https://www.evasis.net/api/images/WA-SET/lights.jpg"/>
                    <Image alt="hue aydınlatma ürünleri salon" src="https://www.evasis.net/api/images/WA-SET/lights2.jpg"/>
                    <Image alt="hue aydınlatma ürünleri oturma odası" src="https://www.evasis.net/api/images/WA-SET/lights3.jpg"/>
                </div>
                <div style={{padding: 20}}>

                    <p>
                        Günlük aydınlatma düzeninizi benzersiz bir deneyime dönüştürün! Beyaz ve sarının tonları olarak
                        renkleri dilediğiniz şekilde değiştirin. Canlanmak için soğuk ışığa veya günün sonunda
                        rahatlamak için sıcak tonlara ayarlayın.
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>3 x E27 ampul</li>
                        <li>Sıcak-soğuk beyaz ışık</li>
                        <li>Uzaktan kontrol edilebilir</li>
                        <li>Dim edilebilir</li>
                        <li>Müzikleriniz ile senkronize edilebilir</li>
                        <li>Hue Bridge ile akıllı kontrol</li>
                    </ul>


                    <h3>Sıcak-soğuk beyaz akıllı ışıklarla havaya girin</h3>
                    <p>
                        Çalışmak, oyun oynamak veya rahatlamak için, günün her saatinde, havaya girmek üzere sıcak-soğuk
                        beyaz ışığın 50.000 tonunu kullanın. Güne enerji veren parlak beyaz ışıkla iyi bir şekilde
                        başlayın veya gece sarı tonlarıyla sakinleşin.
                    </p>
                    <h3>Günlük işler için doğru akıllı aydınlatmanın tadını çıkarın</h3>
                    <p>
                        Günlük işleriniz için önceden ayarlanmış dört ışık sahnesi ile gününüz kolaylaşır. İki soğuk
                        tonlu sahne Energize ve Concentrate sabahları canlı olmanızı ve odaklanmanızı sağlarken daha
                        sıcak sahneler Read ve Relax iyi bir kitabın ve dinlenmiş bir zihnin tadını çıkarmanıza izin
                        verir.
                    </p>
                    <h3>Sizi uyandıran ve uyumanıza yardımcı olan akıllı ışıklar</h3>
                    <p>
                        Işıklarınızı gün doğumunu taklit etmek üzere önceden ayarlanmış bir saatte yavaşça parlayacak
                        şekilde ayarladığınızda, güne sakin ve yenilenmiş hissederek başlarsınız. Akşamlarıysa
                        dinlendirici sıcak beyaz ışık yorgunluğunuzu atmanıza, rahatlamanıza ve vücudunuzu iyi bir gece
                        uykusuna hazırlamanıza yardımcı olur.
                    </p>
                    <h3>Evden uzakta akıllı ışık kontrolü</h3>
                    <p>
                        Philips Hue uygulamasını kullanarak ışıklarınız üzerinde evde olmadığınızda bile tam kontrol
                        sahibi olmanızı sağlar. Evinizin her zaman istediğiniz şekilde aydınlatıldığından emin olmak
                        için yalnızca uygulamayı kullanarak ışıklarınızı kapatıp açabilirsiniz.
                    </p>
                    <h3>Işıkları sesinizle kontrol edin</h3>
                    <p>
                        Hue Bridge'ye bağlandığınızda, ışıklarınızı Alexa, Apple HomeKit ve Google Assistant ile
                        eşleştirebilir ve yalnızca sesinizi kullanarak ışıklarınızı kontrol edebilirsiniz. Basit sesli
                        komutlar ışıkları açıp kapatmanızı, ışıkları kısmanızı veya aydınlatmanızı ve hatta bir ışık
                        sahnesi ayarlamanızı sağlar.
                    </p>
                </div>

            </Row>

            <SubTitle>Akıllı Priz (Tapo P100)</SubTitle>

            <Row>
                <div>
                    <Image alt="akıllı priz teknik" src="https://www.evasis.net/api/images/WA-SET/p100.jpg"/>
                    <Image alt="akıllı priz ütü" src="https://www.evasis.net/api/images/WA-SET/priz.jpg"/>
                </div>

                <div style={{padding: 20}}>
                    <p>
                        Prizlerinize görevler verin. Güvenlik sisteminizi devreye aldığınızda prizleriniz otomatik
                        olarak kapansın veya açılsın. (Örn: Evden çıkarken güvenlik sistemini aktifleştirin, ütü veya
                        çaycınız otomatik kapansın.)
                        Tapo mobil uygulaması üzerinden farklı görevler de tanımlayabilirsiniz.
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>Tamamen Kablosuz</li>
                        <li><b>Uzaktan Kontrol - </b> Tapo uygulaması aracılığıyla nerede olursanız olun bağlı cihazları
                            anında açın ve kapatın
                        </li>
                        <li><b>Zamanlama – </b>Cihazları otomatik olarak yönetmek için bir zamanlama ayarlayın</li>
                        <li><b>Zamanlayıcı - </b>Cihaza bağlı elektronikler için geri sayım sayacı listeleri oluşturun
                        </li>
                        <li><b>Ses İle Kontrol – </b>Akıllı soketinizi Amazon Alexa veya Google Assistant üzerinden
                            sesli komutlarla yönetin
                        </li>
                        <li><b>Dışarıda Modu – </b>Birisinin evde olduğu görünümü vermek için farklı zamanlarda
                            cihazları otomatik olarak açar ve kapatır
                        </li>
                        <li><b>Kompakt Tasarım - </b>Evdeki soketleri bloke etmemek için mini boyut</li>
                        <li><b>Kolay Kurulum ve Kullanım - </b>Hub'a gerek yok, hızlı kurulum ve ücretsiz uygulama ile
                            kolayca yönetim
                        </li>
                    </ul>

                    <h3>Teknik Özellikler</h3>
                    <ul>
                        <li>Kablosuz: 2.4 GHz</li>
                        <li>Protokol: IEEE 802.11b/g/n, Bluetooth 4.2</li>
                        <li>Sistem Gereksinimleri: Android 4.3 or higher, iOS 9.0 or higher</li>
                        <li>Boyutlar (Yükseklik, Genişlik, Derinlik): 2.0* 2.8* 1.6 in (51*72.0*40 mm)</li>
                        <li>Maksimum Yük: 2300 W, 10 A</li>
                        <li>Çalışma sıcaklığı: 0ºC – 35ºC</li>
                    </ul>
                </div>

            </Row>

            <SubTitle>Kumanda</SubTitle>

            <Row>
                <div>
                    <Image alt="dimmer akıllı kumanda" src="https://www.evasis.net/api/images/WA-SET/dimmer1.jpg"/>
                    <Image alt="taşınabilir dimmer akıllı kuamanda" src="https://www.evasis.net/api/images/WA-SET/dimmer2.jpg"/>
                </div>
                <div style={{padding: 20}}>
                    <p>
                        Güvenlik sisteminizi Hue dimmer switch ile kontrol edin. Duvar anahtarı ve uzaktan kumanda
                        olarak kullanılabilen bu akıllı anahtar, birlikte verilen yapıştırıcıyla duvarlara kolayca
                        takılabilir ve uzaktan kumanda olarak kullanılmak üzere manyetik tabandan çıkartılabilir.
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>Tamamen Kablosuz</li>
                        <li>4 tuşa sahiptir</li>
                        <li>Tuşların görevlerini siz belirleyebilirsiniz</li>
                        <li>Taban plakası duvara yapıştırılabilir</li>
                        <li>Taban plakasından çıkartılarak taşınabilir</li>
                        <li>Piller dahildir</li>
                    </ul>

                    <h3>Teknik Özellikler</h3>
                    <ul>
                        <li>Çalışma Nemi: 5% H 95% (yoğuşmasız)</li>
                        <li>Çalışma Sıcaklığı: -10°C – 45°C</li>
                        <li>Pil Tipi: CR - Düğme hücresi</li>
                    </ul>
                </div>
            </Row>


            <SubTitle>Hue Bridge</SubTitle>
            <Row>
                <div>
                    <Image alt="hue bridge network" src="https://www.evasis.net/api/images/WA-SET/bridge.jpg"/>
                    <Image alt="hue bridge connection" src="https://www.evasis.net/api/images/WA-SET/bridge2.jpg"/>
                </div>
                <div style={{padding: 20}}>
                    <p>
                        Hue Bridge, kişisel Philips Hue akıllı aydınlatma sisteminin önemli bir bileşenidir. Akıllı
                        ampuller ve Hue uygulaması ile iletişim kurarak her şeyin birlikte çalıştığından emin olur.
                        Ayrıca, rutinler ve zamanlayıcılar ayarlamak gibi akıllı ev otomasyonu özellikleri sağlar.
                    </p>

                    <h3>Özellikler</h3>
                    <ul>
                        <li>Basit kurulum</li>
                        <li>Akıllı kontrol</li>
                        <li>50 adede kadar ışık ekleyin</li>
                        <li>Sesinizle kontrol edin</li>
                        <li>Güç adaptörü dahil</li>
                        <li>Ethernet ağ kablosu dahil</li>
                    </ul>

                    <h3>Teknik Özellikler</h3>
                    <ul>
                        <li>Çalışma Nemi: 0% H 80% (yoğuşmasız)
                        </li>
                        <li>Çalışma Sıcaklığı: 0°C - 40°C</li>
                        <li>Ağırlık: 280</li>
                        <li>Çap: 88 mm</li>
                        <li>Frenaks Bandı: 2400-2483.5 MHz</li>
                        <li>Uzunluk, Genilşik, Yükseklik: 90.9 x 90.6 x 26 mm</li>
                        <li>Maksimum aksesuar sayısı: 10</li>
                        <li>Maksimum ampul sayısı : 50</li>
                        <li>Montaj Seçenekleri: Masaüstü, Duvar</li>
                        <li>Güç adaptörü: 100-240 V AC / 50-60 Hz Çıkış gerilimi: 5 V DC 600 mA Beklemede güç: maks. 0,1 W</li>
                        <li>Güç tüketimi: 250mA max</li>
                        <li>Ses asistanları: Amazon Alexa, Google Assistant, Apple HomeKit, Microsoft Cortana</li>
                    </ul>
                </div>

            </Row>

            <SubTitle>Örnek Kullanım Planı</SubTitle>
            <div className='text-center'>
                <img alt="evasis setini örnek kullanım planı" style={{maxWidth: 800}} width="100%" src="https://www.evasis.net/api/images/WA-SET/plan.jpg"/>
            </div>

            <Footer/>
        </section>
    )
};

export default WHADetail;