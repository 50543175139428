import React from "react";
import adapter from "../../img/setup/power-adapter.GIF";

const PowerAdapter = props => {
    return (
        <section>
            <h2 className='title'>Güç adaptörünü bağlayın</h2>

            <div className='image-container'>
                <img alt='power adapter' width='100%' src={adapter}/>
            </div>


            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
}

export default PowerAdapter;