import React, {lazy} from "react";
import StepWizard from "react-step-wizard";
import LanConnection from "./LanConnection";
import HueConnection from "./HueConnection";
import HueExist from "./HueExist";
import PowerAdapter from "./PowerAdapter";
import DefaultGateway from "./DefaultGateway";
import FindDefaultGateway from "./FindDefaultGateway";
import SaveApp from "./SaveApp";
import Final from "./Final";
import HueDaylightSensivity from "./HueDaylightSensivity";
import HueAccessories from "./HueAccesories";

const Footer = lazy(() => import('../shared/Footer'));
const Header = lazy(() => import('../shared/Header'));

function stepChanged(){
    document.body.scrollTop = 0; // For Safari
    document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera

}

const Setup = () => {
    return (
        <section>
            <Header/>

            <article className='setup-wizard'>
                <StepWizard onStepChange={() => {stepChanged()}}>
                    <LanConnection/>
                    <PowerAdapter/>
                    <HueExist/>
                    <HueConnection/>
                    <HueAccessories/>
                    <HueDaylightSensivity/>
                    <DefaultGateway/>
                    <FindDefaultGateway/>
                    <SaveApp/>
                    <Final/>
                </StepWizard>
            </article>


            <div className='divider'/>
            <Footer/>
        </section>

    )
};

export default Setup;