import React, {useState} from "react";
import gateway from "../../img/setup/gateway.png";

function replaceLastOctetOfIpAddress(ip, lastPartOfIpAddress){
    const sep = ip.lastIndexOf('.');
    const resultIp = ip.substring(0, sep);

    if(countDotInIp(ip) === 3){
        return resultIp + '.' + lastPartOfIpAddress;
    }else{
        return ''
    }
}

function countDotInIp(string){
    const count = (string.match(/\./g) || []).length;
    return count;
}

const FindDefaultGateway = props => {
    const [value, setValue] = useState('');

    return (
        <section>
            <h2 className='title'>Uygulama Erişim Linki Oluşturun</h2>

            <div className='image-container  bg-white p-4'>

                <p>
                    <ol className={'text-left'}>
                        <li>
                            Gateway adresinizi öğrenmek için
                            <a className={'link-pre-space'} target='_blank' rel="noreferrer" href='http://whatsmyrouterip.com/?ref=244&utm_source=rr&utm_medium=ip'>tıklayın</a>.
                        </li>
                        <li>Gateway adresinizi tablonun sol tarafındaki kutucuğa yazın. <br/> (En alttaki örnek görseli inceleyebilirsiniz)</li>
                        <li>Tablonun sağ tarafında belirtilen linke tıklayın.</li>
                    </ol>

                </p>

                <table className='gateway-table' width="100%">
                    <thead>
                        <tr>
                            <td width="50%">Gateway adresinizi aşağıdaki <b>kutucuğa yazın</b></td>
                            <td width="50%">Uygulama Erişim Linki</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td><input value={value} placeholder='Örnek: 192.168.0.1' onChange={e => setValue(e.target.value)} type='text'/></td>
                            <td>
                                <div className='link-bg2'>
                                    <a target='_blank' rel="noreferrer" href={'http://' + replaceLastOctetOfIpAddress(value, '222:3000')}>{replaceLastOctetOfIpAddress(value, '222:3000')}</a>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
                <br/>

                <b>Örnek gateway adresi</b>
                <img alt='example_gateway' border="1" width='100%' src={gateway}/>

            </div>
            <br/>

            <button className='button danger mr-button' onClick={props.previousStep}>Geri</button>
            <button className='button warning mr-button' onClick={()=>{window.jivo_api.open();}}>Yardım</button>
            <button className='button success' onClick={props.nextStep}>Uygulama açıldı</button>
        </section>

    )
}

export default FindDefaultGateway;