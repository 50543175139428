import React from "react";
import lan from "../../img/setup/lan.GIF";

const LanConnection = props => {
    return (
        <section>
            <h2 className='title'>Kutudan çıkan LAN kablosunun bir ucunu modeminize diğer ucunu ise cihazınıza bağlayın</h2>

            <div className='image-container'>
                <img alt='lan cable' width='100%' src={lan}/>
            </div>

            <button className='button success' onClick={props.nextStep}>Devam</button>
        </section>

    )
};

export default LanConnection;