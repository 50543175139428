import React, {lazy} from "react";

const Footer = lazy(() => import('../shared/Footer'));
const Header = lazy(() => import('../shared/Header'));

const Warranty = () => {
    return (
        <section>
            <Header/>

            <article>
                <iframe title='warranty' width='100%' height='760px' src="https://docs.google.com/document/d/e/2PACX-1vREypz4n9EurMr5bqaGT7QsO651eLMMt3UA2me3p8YUB3yZ5cDN1pJTpnAJlRGJlW_qgKThW57S2iIf/pub?embedded=true"></iframe>
            </article>

            <div className='divider'/>
            <Footer/>
        </section>

    )
};

export default Warranty;