import productImg1 from '../../img/product/4.jpg';
import productImg2 from '../../img/product/1.jpg';
import productImg3 from '../../img/product/2.jpg';
import productImg4 from '../../img/product/3.jpg';
import productImg5 from '../../img/product/5.jpg';
import productImg6 from '../../img/product/6.jpg';
import productImg1_thumb from '../../img/product/4-thumb.jpg';
import productImg2_thumb from '../../img/product/1-thumb.jpg';
import productImg3_thumb from '../../img/product/2-thumb.jpg';
import productImg4_thumb from '../../img/product/3-thumb.jpg';
import productImg5_thumb from '../../img/product/5-thumb.jpg';
import productImg6_thumb from '../../img/product/6-thumb.jpg';
import ImageGallery from 'react-image-gallery';

const images = [
    {
        original: productImg5,
        thumbnail: productImg5_thumb,
    },
    {
        original: productImg6,
        thumbnail: productImg6_thumb,
    },
    {
        original: productImg1,
        thumbnail: productImg1_thumb,
    },
    {
        original: productImg2,
        thumbnail: productImg2_thumb,
    },
    {
        original: productImg3,
        thumbnail: productImg3_thumb,
    },
    {
        original: productImg4,
        thumbnail: productImg4_thumb,
    },

];

function ProductsGallery() {

    return (
        <section>
            <ImageGallery items={images} showNav={false} showPlayButton={false} showFullscreenButton={false} />
        </section>
    )
}

export default ProductsGallery;